<script>
import DepartmentService from "@/services/DepartmentService";
import Cloner from "@/libraries/Cloner";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VDepartmentForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    mixins: [AppCfg],
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            departments: [],
            department: {
                id: null,
                name: null,
                invoice_setup: null,
            },
        };
    },
    computed: {
        invoiceSetups() {
            return this.appCfgRead("Projects.invoice_setup", true);
        },
    },
    async created() {
        if (!this.context.empty) {
            this.editing = true;
            this.department = Cloner.pickClone(this.context, [
                "id",
                "name",
                "invoice_setup",
            ]);
        }
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await DepartmentService.PUT(this.department);
                } else {
                    const r = await DepartmentService.POST(this.department);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal :show="true" @close="$emit('close')" class="asset-modal">
        <template slot="header">
            <template v-if="editing">
                {{ $t("departments.edit") }}
            </template>
            <template v-else>
                {{ $t("departments.create") }}
            </template>
        </template>

        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="department"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('department.name')"
                            :validation-name="$t('department.name')"
                            :placeholder="$t('department.name')"
                            validation="required"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            type="select"
                            :options="invoiceSetups"
                            name="invoice_setup"
                            :validation-name="$t('project.invoice_setup')"
                            :label="$t('project.invoice_setup')"
                            validation="required"
                            :placeholder="
                                $t('project.placeholder_invoice_setup')
                            "
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("departments.save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
