<script>
import DepartmentService from "@/services/DepartmentService";
import VDepartmentForm from "@/components/Forms/VDepartmentForm";

export default {
    name: "VIndex",
    components: {
        VDepartmentForm,
    },
    data() {
        return {
            departments: [],
            loading: false,
            editing: false,
            editContext: { empty: true },
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.USER_MGMT);
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await DepartmentService.GET();
                this.departments = r.data.data;
                console.log("DEPTS", this.departments);
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        handleDelete(role) {
            this.$swal({
                icon: "warning",
                text: this.$t("departments.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async (confirmed) => {
                if (confirmed.isConfirmed) {
                    if (await this.deleteDepartment(role)) {
                        this.$notify({
                            message: this.$t(
                                "departments.notify_department_deleted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success",
                        });
                    }
                }
            });
        },
        async deleteDepartment(role) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await DepartmentService.DELETE(role.id);
                this.fetchData();
                return true;
            } catch (e) {
                this.$handleExceptionError(
                    e,
                    this.$t("price_role.cannot_delete_a_price_role")
                );
            }

            this.loading = false;
            return false;
        },
    },
};
</script>


<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    v-if="ACL_RW"
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("departments.create_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="departments.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t("departments.name")
                                            }}</v-th>
                                            <v-th>{{
                                                $t("departments.invoice_setup")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("departments.usage_count")
                                            }}</v-th>
                                            <v-th
                                                v-if="ACL_RW"
                                                class="text-right"
                                            ></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="d in departments"
                                            :key="d.id"
                                        >
                                            <v-td>
                                                {{ d.name }}
                                            </v-td>
                                            <v-td>
                                                {{ d.invoice_setup_text }}
                                            </v-td>
                                            <v-td class="text-right">{{
                                                d.usage_count
                                            }}</v-td>
                                            <v-td
                                                v-if="ACL_RW"
                                                class="text-right"
                                            >
                                                <base-dropdown
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="setContext(d)"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "departments.action_edit"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item text-danger"
                                                        v-if="
                                                            d.usage_count == 0
                                                        "
                                                        @click="handleDelete(d)"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "departments.action_delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-department-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>
